<template>
    <div class="container-fluid">
        <MenuToday />
       
    </div>
  </template>
  <script>
import MenuRecord from './MenuRecord.vue';
import MenuToday from './MenuToday.vue';
import menuStoreModule from './menuStoreModule'
import store from '@/store'
import {onUnmounted} from '@vue/composition-api'
  export default {
    components: {
        MenuRecord,
        MenuToday,
    },
    setup(){
    const MENU_STORE_MODULE = 'hr-menu'
    // Register module
    if (!store.hasModule(MENU_STORE_MODULE)) store.registerModule(MENU_STORE_MODULE, menuStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MENU_STORE_MODULE)) store.unregisterModule(MENU_STORE_MODULE)
    })

    }
  };
  </script>
  <style></style>
  