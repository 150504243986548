<template>
  <div>
    <b-card>
        <h1>MenuRecordTab</h1>
    </b-card>
  </div>
</template>
<script>
import { BCard, BCardBody, BCardFooter, BCardTitle } from "bootstrap-vue";
export default {
  components: {
    BCard,
  },
};
</script>
<style>

</style>
