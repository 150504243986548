import axios from '@axios'

export default {
  namespaced: true,
  state: {
    importedMenu:null
  },
  getters: {
    getImportedMenu(state){
      return state.importedMenu
    }
  },
  mutations: {
    setImportedMenu(state,data){
      state.importedMenu=data;
    }
  },
  actions: {
    getImportedMenu(context,data){
      context.commit('setImportedMenu',data);
    }    
  },
}
